import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constraints = keyMirror({
    GET_DASH_PROJECTS_REQUEST: null,
    GET_DASH_PROJECTS_SUCCESS: null,
    GET_DASH_PROJECTS_FAILURE: null,

    GET_DEV_SCHEDULE_REQUEST: null,
    GET_DEV_SCHEDULE_SUCCESS: null,
    GET_DEV_SCHEDULE_FAILURE: null,

    SET_DASHBOARD_URL: null,
});

export const {
    getDashProjectsRequest,
    getDashProjectsSuccess,
    getDashProjectsFailure,
} = createActions(
    constraints.GET_DASH_PROJECTS_REQUEST,
    constraints.GET_DASH_PROJECTS_SUCCESS,
    constraints.GET_DASH_PROJECTS_FAILURE,
);

export const {
    getDevScheduleRequest,
    getDevScheduleSuccess,
    getDevScheduleFailure,
} = createActions(
    constraints.GET_DEV_SCHEDULE_REQUEST,
    constraints.GET_DEV_SCHEDULE_SUCCESS,
    constraints.GET_DEV_SCHEDULE_FAILURE,
);

export const {
    setDashboardUrl,
} = createActions(
    constraints.SET_DASHBOARD_URL,
);
