/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Flex, Row, Spin, Table } from 'antd';
import moment from 'moment';
import Main from '../../components/layout/Main';
import './devSchedule.scss';
import { logoutRequest } from '../../core/user/userActions';
import { getDevScheduleRequest } from '../../core/dashboard/dashboardActions';
import { ArrowLeftIcon, ArrowRightIcon } from '../../components/Elements/CustomSVGIcon';

const DevSchedule = () => {
    const dispatch = useDispatch();
    const { devSchedule, isFetching } = useSelector((state) => state?.dashboard) || {};
    const minWeek = moment().startOf('isoWeek').subtract(1, 'weeks').format('YYYY-MM-DD'); // Maximum backward
    const maxWeek = moment().startOf('isoWeek').add(1, 'weeks').format('YYYY-MM-DD'); // Maximum forward

    const [week, setWeek] = useState(moment().startOf('isoWeek').format('YYYY-MM-DD'));
    const handleLogout = () => {
        logoutRequest();
    };

    useEffect(() => {
        dispatch(getDevScheduleRequest({ week }));
    }, [week]);

    const handleLeftClick = () => {
        const newWeek = moment(week).subtract(1, 'weeks').format('YYYY-MM-DD');
        if (newWeek >= minWeek) {
            setWeek(newWeek);
        }
    };

    const handleRightClick = () => {
        const newWeek = moment(week).add(1, 'weeks').format('YYYY-MM-DD');
        if (newWeek <= maxWeek) {
            setWeek(newWeek);
        }
    };

    const currentYear = new Date().getFullYear();
    const [schedule, setSchedule] = useState([]);

    useEffect(() => {
        setSchedule(devSchedule);
    }, [devSchedule]);

    const formatDate = (date) => {
        if (!date) return '';
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Intl.DateTimeFormat('en-GB', options).format(new Date(date));
    };
    const devScheduleTableHeader = () => (
        <Row className="header-style" justify="center">
            <span style={{ color: '#aaa', marginRight: 10 }}>
                <Button icon={<ArrowLeftIcon />} type="link" onClick={handleLeftClick} disabled={isFetching} />
            </span>
            <h4 style={{ marginTop: '5px' }}>Week Commencing: {formatDate(devSchedule?.week)}</h4>
            <span style={{ color: '#aaa', marginLeft: 10 }}>
                <Button icon={<ArrowRightIcon />} type="link" onClick={handleRightClick} disabled={isFetching} />
            </span>
        </Row>
    );

    const scheduleColumns = () => (
        <Row className="background border-bottom">
            <Col className="border-rigth  col-padding" span={4}>
                <h4>Name </h4>
            </Col>
            <Col className="col-padding" span={20}>
                <Row justify="space-between">
                    <h4>Project Schedule </h4>
                    <Flex justify="flex-end">
                        {(devSchedule?.update_at) && <p style={{ color: '#212529' }}>Last Updated: {(schedule?.update_at)}</p>}
                    </Flex>
                </Row>

            </Col>
        </Row>
    );

    const ScheduleRows = () => {
        // Validating if the schedule is available and not empty
        if (devSchedule?.schedule?.length > 0) {
            return devSchedule.schedule.map((record) => ( // Ensure you return JSX properly from the map function
                <Row className="background border-bottom" key={record?.id}>
                    <Col className="border-rigth col-padding" span={4}>
                        <h4>{record?.name}</h4>
                    </Col>
                    <Col span={20}>
                        <Row>
                            {record?.schedules?.map((item) => (
                                <Col className="border-rigth border-bottom col-padding col-width margin" key={item?.projectCode} style={{ margin: '2px' }}>
                                    <p>{item?.projectCode}</p>
                                    {/* Assuming handleDaysCal is a function defined elsewhere */}
                                    <p>{item?.totalDays} Days</p>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            ));
        }
        // Handling case where there's no data
        return <Row className="background border-bottom col-padding" justify="center" align="middle">No data</Row>;

    };
    const renderScheduleTable = () => (
        <div style={{ flex: 1, width: '100%', margin: 5 }}>
            {devScheduleTableHeader()}
            {scheduleColumns()}
            {ScheduleRows()}
        </div>
    );
    return (
        <Main
            className="login"
            title={<div>Welcome</div>}
            headerTitle={<div>Dashboard</div>}
            menuWidth={350}
            menuPlacement="right"
            floatingHeader={false}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; Scorchsoft Ltd {currentYear} </div>}
            floatingFooter
            onLogout={handleLogout}>
            <div style={{ marginInline: '50px' }}>
                <div style={{ marginTop: '20px', marginBottom: '20px', alignContent: 'center' }}>
                    <h1>Developer Schedule</h1>
                </div>

                <div style={{ marginBottom: '100px' }}>
                    {(!devSchedule || isFetching) ? (<Spin style={{ margin: 10 }} size="large" />) : renderScheduleTable()}
                </div>
            </div>
        </Main>
    );
};

export default DevSchedule;

DevSchedule.propTypes = {};
