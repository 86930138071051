import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import Routes from './core/routes';
import debug from './core/utils/debug';

import './styles/style.css';
/** Actions: The necessary actions for dispatching our bootstrap values */
import { setVersion } from './core/global/globalActions';

/** The version of the app but not displayed yet */
import pack from '../package.json';

/** configureStore */
import configureStore from './core/store';
import { ProjectProvider } from './components/ProjectProvider';

export { default as Calendar } from './components/PickerComponent/Calender';
export { default as DatePicker } from './components/PickerComponent/DatePicker';
// Get bugsnag error wrapper
const ErrorWrapper = debug.getWrapper();

const globalStore = configureStore({});
const { store, persistor } = globalStore;

const VERSION = pack.version;

store.dispatch(setVersion(VERSION));

ReactDOM.render(
    <ErrorWrapper>
        <Provider store={store}>
            <ProjectProvider>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter basename="/">
                        <Routes />
                    </BrowserRouter>
                </PersistGate>
            </ProjectProvider>
        </Provider>
    </ErrorWrapper>,
    document.getElementById('root'),
);
