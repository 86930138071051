import { useSelector } from 'react-redux';

const useCan = () => {
    const { userData: user } = useSelector((state) => state?.user || {});

    const can = (permission) => !!(user?.permissions || []).find((p) => p === permission);
    return { can };
};

export default useCan;
