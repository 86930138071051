import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getBugSummaryRequest, resetBugSummaryRequest } from '../../core/agileProject/agileProjectActions';

import CoreTable from '../../components/CoreTable/CoreTable';
import { TYPES } from '../../components/CoreTable/config';

import './bugRateAnalysis.scss';

const AgileBugRateAnalysis = () => {
    const [fixVersions, setFixVersions] = useState([]);

    const projectData = useSelector((state) => state?.agileProject);
    const settings = projectData?.settings;
    const bugSummary = projectData?.bugSummary;
    const isFetching = projectData?.isFetching;
    const [filterInfo, setFilterInfo] = useState(null);
    const [sorterInfo, setSorterInfo] = useState(null);
    const handleFilterChange = (filter) => {
        setFilterInfo(filter);
    };

    const handleSorterChange = (sorter) => {
        setSorterInfo(sorter);
    };

    const { fixVersions: allFixVersions } = settings;
    const { bugPriority, overallBugRate, timeUsed, timeRemaining, originalEstimate, bugTicketBreakdown } = bugSummary;

    const fixVersionOptions = allFixVersions?.map((version) => ({
        label: version?.name,
        value: version?.id,
    }));

    const dispatch = useDispatch();

    useEffect(() => {
        if (fixVersions?.length > 0) {
            dispatch(getBugSummaryRequest({ projectId: settings?.id, fixVersions }));
        } else {
            dispatch(resetBugSummaryRequest());

        }
    }, [fixVersions]);

    const prioritiesList = ['High', 'Medium', 'Low', 'Lowest', 'Highest'];
    const summary = prioritiesList?.map(priorityName => {
        const priorityObject = bugPriority?.find(priority => priority?.name === priorityName);
        if (!priorityObject) {
            return {
                priority: priorityName,
                tickets: 0,
                reopenCount: 0,
                originalEstimate: 0,
                timeSpent: 0,
                timeRemaining: 0,
            };
        }
        return {
            priority: priorityName,
            tickets: priorityObject?.ticketsCount || 0,
            reopenCount: priorityObject?.reopenCount || 0,
            originalEstimate: priorityObject?.originalEstimate || 0,
            timeSpent: priorityObject?.timeSpent || 0,
            timeRemaining: priorityObject?.timeRemaining || 0,
        };
    });
    const totals = {
        priority: 'Total',
        tickets: 0,
        reopenCount: 0,
        originalEstimate: 0,
        timeSpent: 0,
        timeRemaining: 0,
    };

    // Iterate over the summary to accumulate each count

    summary.forEach(item => {
        totals.tickets += Number(item.tickets);
        totals.reopenCount += Number(item.reopenCount);
        totals.originalEstimate += Number(item.originalEstimate);
        totals.timeSpent += Number(item.timeSpent);
        totals.timeRemaining += Number(item.timeRemaining);
    });
    const getStat = (title, value) => (
        <div className="stat">
            <h3>{title}</h3>
            <h1>{value}</h1>
        </div>
    );

    const getTableRow = (data, index) => (
        <tr key={index}>
            <td style={{ fontWeight: index === 'total' ? '600' : 'normal' }}>{data?.priority}</td>
            <td style={{ fontWeight: index === 'total' ? '600' : 'normal' }}>{data?.tickets}</td>
            <td style={{ fontWeight: index === 'total' ? '600' : 'normal' }}>{data?.reopenCount}</td>
            <td style={{ fontWeight: index === 'total' ? '600' : 'normal' }}>{data?.originalEstimate}</td>
            <td style={{ fontWeight: index === 'total' ? '600' : 'normal' }}>{data?.timeSpent}</td>
            <td style={{ fontWeight: index === 'total' ? '600' : 'normal' }}>{data?.timeRemaining}</td>
        </tr>
    );

    const handleChange = (value) => {
        setFixVersions(value);

    };
    // Handler to reset the selection
    const handleReset = () => {
        setFixVersions(null);
        dispatch(resetBugSummaryRequest());
    };

    return (
        <div className="bug-rate-container">
            <div className="filters-wrapper">
                <p className="filter-label">Version</p>
                <div className="filters-container">
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                            width: 250,
                        }}
                        value={fixVersions}
                        onChange={handleChange}
                        options={fixVersionOptions}
                        disabled={isFetching} />
                    <span className="reset-button" onClick={handleReset}>Reset Filters</span>
                </div>
            </div>
            {isFetching ? (
                <Spin style={{ position: 'absolute', top: 240, left: 500 }} />
            ) : (
                <>
                    <h1 className="bug-rate-title">Overview</h1>
                    <div className="overview-container">
                        <div className="stats-wrapper">
                            {getStat('Overall Bug Rate', `${overallBugRate || 0}%`)}
                            {getStat('Original Estimate (Bugs)', originalEstimate || 0)}
                            {getStat('Time Spent (Bugs)', timeUsed || 0)}
                            {getStat('Time Remaining (Bugs)', timeRemaining || 0)}
                        </div>
                        <div className="table-wrapper">
                            <div className="table-heading">
                                <h3>Bug Tickets By Priority</h3>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Priority</th>
                                        <th>Tickets</th>
                                        <th>Reopen Count</th>
                                        <th>Original Estimate</th>
                                        <th>Time Spent</th>
                                        <th>Time Remaining</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { summary.map((data, index) => getTableRow(data, index))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <h1 className="bug-rate-title">Bug Ticket Breakdown</h1>
                    <div className="breakdown-wrapper">
                        <CoreTable
                            type={TYPES.BUG_RATE_ANALYSIS}
                            dataSource={Array.isArray(bugTicketBreakdown) ? bugTicketBreakdown : []}
                            isFetching={isFetching}
                            filterInfo={filterInfo}
                            handleFilterChange={handleFilterChange}
                            sorterInfo={sorterInfo}
                            handleSorterChange={handleSorterChange} />
                    </div>
                </>
            )}
        </div>
    );
};

export default AgileBugRateAnalysis;

AgileBugRateAnalysis.propTypes = {
    project: PropTypes.object,
};

AgileBugRateAnalysis.defaultProps = {
    project: {},
};
