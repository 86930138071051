import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import lockr from 'lockr';
import { fetchApi, fetchApiAuth } from '../utils/api';
import history from '../utils/history';
import {
    registerSuccess,
    registerFailure,
    loginSuccess,
    loginFailure,
    logoutSuccess,
    logoutFailure,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    resetPasswordSuccess,
    resetPasswordFailure,
    getAllUserSuccess,
    getAllUserFailure,
} from './userActions';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
    LOCALSTORAGE_EXPIRES_TIME,
} = require('../constants').default;

const {
    REGISTER_REQUEST,
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_REQUEST,
    GET_ALL_USER_REQUEST,
} = require('./userActions').constants;

function saveSessionToken(action) {
    const authDate = new Date();
    authDate.setMinutes(authDate.getMinutes() + 30);
    lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
    lockr.set(AUTH_KEY, action.data.token);
}

function* register({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/register',
            body: {
                email: payload.data.email,
                password: payload.data.password,
                first_name: payload.data.firstName,
                last_name: payload.data.lastName,
            },
        });
        history.push('/');
        yield put(registerSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(registerFailure(e.response ? e.response.data.message : e));
    }
}

function* makeLoginRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'login',
            body: { email: action.payload.email, password: action.payload.password },
        });

        if (response.data.token) {
            yield call(saveSessionToken, response);
            yield put(loginSuccess(response.data));
        } else {
            yield put(loginFailure('login request failed'));
        }
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(loginFailure(e.response ? e.response.data.errors.email : e));
    }
}

function* logoutRequest() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/logout',
            body: {},
        });
        lockr.rm(AUTH_KEY);
        lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
        lockr.rm(LOCALSTORAGE_EXPIRES_TIME);
        localStorage.clear();
        yield put(logoutSuccess(response));
        // eslint-disable-next-line no-undef
        window.location.href = '/';
    } catch (e) {
        yield put(logoutFailure(e.response ? e.response.data.message : e));
    }
}

function* forgotPassword({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/forgot-password',
            body: { email: payload.email },
        });

        yield put(forgotPasswordSuccess(response));
        message.success('A password reset email has been requested, if an account exists you will receive an email shortly.');
    } catch (e) {
        yield put(forgotPasswordFailure(e.response ? e.response?.data?.errors?.email[0] : e));
    }
}

function* resetPassword({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'reset-password',
            body: {
                email: payload.email,
                password: payload.password,
                password_confirmation: payload.passwordConfirmation,
                token: payload.token,
            },
        });

        yield put(resetPasswordSuccess(response));
        message.success('Password updated successfully');
        history.push('/');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(resetPasswordFailure(e.response ? e.response.data.message : e));
    }
}

function* getAllUser() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/users',
        });
        yield put(getAllUserSuccess(response.data.users));
    } catch (e) {
        yield put(getAllUserFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(REGISTER_REQUEST, register),
        takeEvery(LOGIN_REQUEST, makeLoginRequest),
        takeEvery(LOGOUT_REQUEST, logoutRequest),
        takeEvery(FORGOT_PASSWORD_REQUEST, forgotPassword),
        takeEvery(RESET_PASSWORD_REQUEST, resetPassword),
        takeEvery(GET_ALL_USER_REQUEST, getAllUser),
    ]);
}
