import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, Spin } from 'antd';

import CoreTable from '../../components/CoreTable/CoreTable';
import { TYPES } from '../../components/CoreTable/config';

import './PullRequest.css';
import { getPullRequestsRequest } from '../../core/agileProject/agileProjectActions';
import ObjectFilters from '../../components/EditableTable/filters/ObjectFilters';

const PullRequest = () => {
    const projectData = useSelector((state) => state?.agileProject);
    const { pullRequestsPagination, pullRequests = [], isFetchingPullRequests, pullRequestFilters: filterOptions } = useSelector((state) => state.agileProject);
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [filterInfo, setFilterInfo] = useState(null);
    const [filetedData, setFilteredData] = useState(pullRequests);
    const [filters, setFilters] = useState(null);

    const settings = projectData?.settings;

    const handleChangePage = (pageNumber) => {
        setPage(pageNumber);
    };
    const handleChangePageSize = (current, size) => {
        setPageSize(size);

    };
    const handleFilterChange = (filter) => {
        setFilterInfo(filter);
    };

    useEffect(() => {
        setFilters(null);

        if (filterOptions) {
            const initialFilters = {
                creator: ObjectFilters('creator', filterOptions),
                status: ObjectFilters('status', filterOptions),
                reviewer: ObjectFilters('reviewer', filterOptions),
            };
            setFilters(initialFilters);
        }
    }, [filterOptions]);

    useEffect(() => {
        setFilteredData(pullRequests);
    }, [pullRequests]);

    useEffect(() => {
        dispatch(getPullRequestsRequest({ projectId: settings.id, pageSize, filters: filterInfo }));
    }, [filterInfo, pageSize]);

    useEffect(() => {
        dispatch(getPullRequestsRequest({ projectId: settings.id, pageSize, page, filters: filterInfo }));
    }, [page]);

    const showTotal = (total) => `Results: ${pullRequestsPagination.from} - ${pullRequestsPagination.to} of ${total}`;

    return (
        <div style={{ margin: 20 }}>

            <div className="sm-seperator" />
            {
                isFetchingPullRequests && (
                    <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                        <Spin size="large" />
                    </div>
                )
            }
            {
                !isFetchingPullRequests && settings && (
                    <>
                        <CoreTable
                            type={TYPES.PULL_REQUEST}
                            dataSource={filetedData}
                            isFetching={isFetchingPullRequests}
                            projectName={settings.projectName}
                            clientName={settings.clientName}
                            filterInfo={filterInfo}
                            handleFilterChange={handleFilterChange}
                            filterValue={filters} />

                        <div className="sm-seperator" />

                        <div className="custom-pagination-container">

                            { pullRequests.length > 0 && (
                                <Pagination
                                    showSizeChanger
                                    disabled={isFetchingPullRequests}
                                    pageSizeOptions={['10', '50', '100', '500', '1000']}
                                    current={pullRequestsPagination.current}
                                    total={pullRequestsPagination.total}
                                    pageSize={pullRequestsPagination.page_size}
                                    showTotal={showTotal}
                                    onChange={handleChangePage}
                                    onShowSizeChange={handleChangePageSize} />
                            )}

                        </div>
                    </>
                )
            }
        </div>
    );
};

export default PullRequest;
