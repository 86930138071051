import { call, put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';
import {
    getDashProjectsSuccess,
    getDashProjectsFailure,
    getDevScheduleSuccess,
    getDevScheduleFailure,
} from './dashboardActions';

const {
    GET_DASH_PROJECTS_REQUEST,
    GET_DEV_SCHEDULE_REQUEST,
} = require('./dashboardActions').constraints;

function* getProjects() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/projects?managers=true',
        });
        yield put(getDashProjectsSuccess(response?.data));
    } catch (e) {
        yield put(getDashProjectsFailure(e.response ? e.response.data.message : e));
    }
}
function* getDevSchedule({ payload }) {
    const { week } = payload;

    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/schedule?week=${week}`,
        });
        yield put(getDevScheduleSuccess(response?.data));
        message.success('Schedule Successful');

    } catch (e) {
        yield put(getDevScheduleFailure(e.response ? e.response.data.message : e));
    }
}

export default function* dashboardSagas() {
    yield* [
        takeEvery(GET_DASH_PROJECTS_REQUEST, getProjects),
        takeEvery(GET_DEV_SCHEDULE_REQUEST, getDevSchedule),
    ];
}
