import React, { useContext } from 'react';
import { Card, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { CloseCircleFilled, PlusCircleFilled } from '@ant-design/icons';

import { ProjectContext } from '../ProjectProvider';
import './style.scss';
import view, { colors } from '../../core/utils/values';
import projectTypes from '../../core/utils/projectTypes';

const CardComponent = ({ projects }) => {
    const { setProject } = useContext(ProjectContext);
    const renderProjectType = (type) => {
        const foundProjectType = projectTypes.find((projectType) => type === projectType.value);

        if (foundProjectType) {
            if (type === 10) {
                return 'Waterfall';
            }
            return foundProjectType.name;
        }

        return 'Unknown Project Type';
    };
    // Ensure that reason is properly parsed
    const calculateStatus = (status) => {
        if (status === colors.COLOR_RED) {
            return 'FF0000';
        }
        if (status === colors.COLOR_RED) {
            return 'FFBF00';
        }
        return '549C30';
    };
    return (
        <div className="card-container">
            {projects.map((project) => {
                const { id } = project;
                let reasons = [];
                try {
                    reasons = JSON.parse(project?.project_health_status?.reason);
                    if (!Array.isArray(reasons)) {
                        reasons = [];
                    }
                } catch (e) {
                    console.error('Failed to parse reasons:', e);
                }
                const status = calculateStatus(project?.project_health_status?.status);
                return (
                    <div className="card" key={id}>
                        <Link to={`/report/${project.id}?view=${view[0].view}`}>
                            <Card
                                onClick={() => setProject(project)}
                                style={{ borderRadius: 10, backgroundColor: project.status ? 'white' : '#ECECEC' }}
                                hoverable
                                className="card-component">
                                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'self-start' }}>
                                    <div>
                                        <Tooltip color={`#${status}`}
                                            title={
                                                reasons.length > 0 ? (
                                                    <ul style={{ paddingLeft: '20px', margin: 0 }}>
                                                        {reasons.map((reason) => (
                                                            <li key={reason}>{reason}</li>
                                                        ))}
                                                    </ul>
                                                ) : ('No reasons available')
                                            }>
                                            <div className="colour_circle" style={{ backgroundColor: `#${status === 'FF0000' ? 'FF0000' : 'fffff'}`, justifySelf: 'flex-start' }} />
                                            <div className="colour_circle" style={{ backgroundColor: `#${status === 'FFBF00' ? 'FFBF00' : 'fffff'}`, justifySelf: 'flex-start' }} />
                                            <div className="colour_circle" style={{ backgroundColor: `#${status === '549C30' ? '549C30' : 'fffff'}`, justifySelf: 'flex-start' }} />
                                        </Tooltip>
                                    </div>

                                    <h2 className="card-code">{project.code}</h2>
                                    <div className="colour_circle" style={{ backgroundColor: '#fffff', justifySelf: 'flex-start', borderWidth: 0 }}>
                                        { !project?.status ? (
                                            <CloseCircleFilled style={{ color: '#d11208', aspectRatio: 1, fontSize: 25.5 }} />
                                        ) : (
                                            <PlusCircleFilled style={{ color: '#14d948', aspectRatio: 1, fontSize: 25.5 }} />
                                        )}
                                    </div>
                                </div>
                                <h2 className="card-name">{project.name}</h2>
                                <p className={!project?.project_manager ? 'card-error' : null}>PM: {project?.project_manager?.name || 'Undefined'}</p>
                                <p className={!project?.lead_dev ? 'card-error' : null}>LD: {project?.lead_dev?.name || 'Undefined'}</p>
                                <p className="card-agile-text">{renderProjectType(project?.type)}</p>
                            </Card>
                        </Link>
                    </div>
                );
            })}
        </div>
    );
};

CardComponent.propTypes = {
    projects: PropTypes.array,
};

CardComponent.defaultProps = {
    projects: null,
};

export default CardComponent;
