import React from 'react';
import PropTypes from 'prop-types';

import { CoreRouter, LogoutRoute } from './utils/coreRouter';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import ProgrammeOverview from '../pages/ProgrammeOverview';
import MQProductivityBreakdown from '../pages/MQProductivityBreakdown';
import MQAccuracyReview from '../pages/MQAccuracyReview';
import ProjectSettings from '../pages/ProjectSettings';
import Overview from '../pages/Overview';
import Report from '../pages/Report';
import DevSchedule from '../pages/Schedule';

const Routes = () => (
    <CoreRouter routes={[
        { path: '/', component: Login, type: 'default' },
        { path: '/dashboard', component: Dashboard, type: 'auth' },
        { path: 'programme-overview', component: ProgrammeOverview, type: 'auth' },
        { path: '/m-q-productivity-breakdown', component: MQProductivityBreakdown, type: 'auth' },
        { path: '/m-q-accuracy-review', component: MQAccuracyReview, type: 'auth' },
        { path: '/report/:projectCode/:projectName', component: ProjectSettings, type: 'auth' },
        { path: '/report/create', component: ProjectSettings, type: 'auth' },
        { path: '/overview', component: Overview, type: 'auth' },
        { path: '/report/:id', component: Report, type: 'auth' },
        { path: '/schedule', component: DevSchedule, type: 'auth' },
        { path: '/logout', component: LogoutRoute, type: 'default' },
        { path: '*', component: Login },
    ]} />
);

Routes.defaultProps = {
    persistor: null,
};

Routes.propTypes = {
    persistor: PropTypes.object,
};

export default Routes;
