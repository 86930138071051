import React from 'react';
import moment from 'moment';
import { toHours } from '../../core/utils/time';

const renderImage = (data) => {
    if (data) {
        return (
            <img
                style={{ width: '25px', height: '25px' }}
                src={data?.icon_url}
                alt={data?.name} />
        );
    }

    return '';
};

const renderList = (data) => {
    if (data) {
        const names = data?.map(d => d?.name);
        return names.join(', ');
    }

    return '';
};

const renderLink = (data) => (
    <a href={`https://scorchsoft.atlassian.net/browse/${data}`}>
        { data }
    </a>
);

const renderHours = (data) => {
    if (data) {
        return toHours(data);
    }

    return 0;
};

const renderPeriod = (data) => {
    if (moment(data, true).isValid()) {
        return moment(data).format('DD/MM/YYYY');
    }
    if (moment(data, 'YYYY-MM-DD', true).isValid()) {
        return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
    return data;
};

const renderInitals = (data) => {
    if (data) {
        return (
            data.split(' ').map(part => part.charAt(0)).join('')).toUpperCase();
    }

    return '';
};

const renderInitialsFromArray = (names) => {
    // Check if names is an array and has at least one element
    if (Array.isArray(names) && names.length > 0) {
        // Map through names array and convert each name to initials
        return names.map((name) => name
            .split(' ')
            .map((part) => part.charAt(0))
            .join('')
            .toUpperCase())
            .join(', '); // Join initials with a comma and space
    }

    // Return an empty string if names is not a valid array
    return '-';
};

export { renderImage, renderList, renderLink, renderHours, renderPeriod, renderInitals, renderInitialsFromArray };
