import React from 'react';
import PropTypes from 'prop-types';
import { Row, Typography } from 'antd';

import FormItem from '../FormItem';

import './style.scss';

const { Text, Title } = Typography;

const pushIfUnique = (target, source) => {
    source.forEach(elem => {
        if (target.indexOf(elem) === -1) {
            target.push(elem);
        }
    });
};

const getDependencies = (item) => {
    const { conditions: displayConditions } = item?.hidden || {};
    const { conditions: disabledConditions } = item?.config?.disabled || {};
    const dependencies = [];

    if (displayConditions) {
        pushIfUnique(dependencies, displayConditions.map(({ when }) => when));
    }
    if (disabledConditions) {
        pushIfUnique(dependencies, disabledConditions.map(({ when }) => when));
    }

    return dependencies;
};

const FormSection = ({ form, title, subtitle, customRender, ...config }) => {
    const { initialValues } = config;

    const renderItem = (item) => {
        const tempItem = item;
        const tempConfig = item.config || {};

        tempItem.initialValues = initialValues;
        tempItem.config = tempConfig;
        tempConfig.dependencies = getDependencies(item);

        return <FormItem key={item.id || item.name} form={form} {...tempItem} />;
    };

    return (
        <div className="form-section p-12 mb-16 shadow-lg">
            <div className="mb-8">
                <Row justify="center">
                    <Title level={4}>{title}</Title>
                </Row>
                <Row justify="center">
                    <Text>{subtitle}</Text>
                </Row>
            </div>
            {customRender || (config.items.map((item) => (
                renderItem(item)
            )))}

        </div>
    );
};

FormSection.propTypes = {
    subtitle: PropTypes.string,
    title: PropTypes.string,
    form: PropTypes.any,
    customRender: PropTypes.node,
};

FormSection.defaultProps = {
    subtitle: '',
    title: '',
    form: null,
    customRender: null,
};

export default FormSection;
