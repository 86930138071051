const view = [
    { view: 'overview' },
];

const type = [
    { sprint: 30 },
    { retainer: 20 },
    { fixed: 10 },
];
export default view;

const colors = {
    COLOR_RED: 'red',
    COLOR_AMBER: 'green',
    COLOR_GREEN: 'amber',
};
export { type, colors };
