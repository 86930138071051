/* eslint-disable */
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import lockr from 'lockr';
import { notification } from 'antd';
import { isUndefined } from 'underscore';

import NotFound from '../../pages/NotFound';
import { logoutRequest } from '../user/userActions';
import { useDispatch, useSelector } from 'react-redux';
const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
    LOCALSTORAGE_EXPIRES_TIME,
} = require('../constants').default;

const onlyAuthorisedAllowed = () => {
    const response = lockr.get(AUTH_KEY);

    if (!isUndefined(response)) {
        const authDate = lockr.get(LOCALSTORAGE_EXPIRES_KEY);
        if (!isUndefined(authDate)) {
            const parsedAuthDate = new Date(authDate);
            const now = new Date();
            const differenceMilliseconds = now - parsedAuthDate;
            const differenceMinutes = Math.floor(differenceMilliseconds / 1000 / 60);
            if (differenceMinutes >= LOCALSTORAGE_EXPIRES_TIME) {
                notification.error({ message: 'Error', description: 'Session expired. Please login again.' });
                return false;
            }
        } else {
            notification.error({ message: 'Error', description: 'Session expired. Please login again.' });
            return false;
        }
        return true;
    }
    return false;
};

const ProtectedRoute = ({ children }) => {
    if (onlyAuthorisedAllowed()) {
        return <>{children}</>;
    }
    const user = useSelector(state => state.user);
    if(user?.isLoggedIn){
        const dispatch = useDispatch();
        dispatch(logoutRequest());
    }
    return <Navigate to="/" />;
}
const cleanUpLocalStorage = () => {
    lockr.rm(AUTH_KEY);
    lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
    lockr.rm(LOCALSTORAGE_EXPIRES_TIME);

    localStorage.clear();
    lockr.flush();
    persistor?.flush();
    persistor?.purge();
    return null;
}
const LogoutRoute = ({ exact, path, persistor }) => {
    return cleanUpLocalStorage();
}

const CoreRouter = ({ routes }) => (
    <Routes>
        {routes.map((route, index) => {
                let element = <route.component/>;
                if (route.type === 'auth') {
                    element = <ProtectedRoute>
                        <route.component/>
                    </ProtectedRoute>;
                }
                return <Route
                    key={index}
                    exact
                    path={route.path}
                    element={element} />;
            }
        )}
        <Route element={<NotFound />} />
    </Routes>
);

export {
    CoreRouter,
    onlyAuthorisedAllowed,
    LogoutRoute,
};
